<template>
  <div class="fee-container">
    <page-top :title="$t('fee.title')">
      <span style="font-size:26px;" class="fa fa-money"></span>
    </page-top>
    <el-card>
      <div slot="header" class="header">
        <i class="fa fa-info-circle"></i>
        <span class="title">{{$t('fee.card')}}</span>
      </div>
      <section class="fee-body">
        <el-row>
          <el-col :span="6" :offset="1">
            <el-input class="sales-input" v-model="sales">
              <i slot="prefix" class="input-prefix">&#36;</i>
              <i slot="suffix" class="el-input__icon el-icon-edit"></i>
            </el-input>
          </el-col>
          <el-col :span="1" :offset="2">
            <i class="math-icon">&times;</i>
          </el-col>
          <el-col :span="6">
            <span class="rate">3%</span>
          </el-col>
          <el-col :span="1">
            <i class="math-icon">&#61;</i>
          </el-col>
          <el-col :span="6">
            <el-input class="sales-total" v-model="salesTotal" disabled>
              <i slot="prefix" class="input-prefix">&#36;</i>
            </el-input>
          </el-col>
        </el-row>
        <hr />

        <el-row>
          <el-col :span="6" :offset="1">
            <el-input class="sales-total2" v-model="salesTotal2" disabled>
              <i slot="prefix" class="input-prefix">&#36;</i>
              <i slot="suffix" class="el-input__icon el-icon-edit"></i>
            </el-input>
          </el-col>
          <el-col :span="1" :offset="2">
            <i class="math-icon">&#45;</i>
          </el-col>
          <el-col :span="6">
            <span class="rate point">$300</span>
          </el-col>
          <el-col :span="1">
            <i class="math-icon">&#61;</i>
          </el-col>
          <el-col :span="6">
            <el-input class="real-total" v-model="realTotal" disabled>
              <i slot="prefix" class="input-prefix">&#36;</i>
            </el-input>
          </el-col>
        </el-row>
      </section>
    </el-card>
  </div>
</template>

<script>
import pageTop from '../../components/PageTop/PageTop'
export default {
  data () {
    return {
      sales: 100000,
      salesTotal: 3000,
      salesTotal2: 3000,
      realTotal: 1700

    };
  },

  components: {
    pageTop
  },

  methods: {}
}

</script>
<style lang="scss" scoped>
.fee-container {
  .header {
    display: flex;
    font-size: 16px;
    align-items: center;
    font-weight: 900;
    span {
      margin-left: 10px;
    }
  }

  .fee-body {
    color: #333;
    .el-row {
      padding: 20px 0;
    }
    .el-col {
      padding-top: 35px;
      .sales-input,
      .sales-total {
        position: relative;
      }

      .rate {
        display: block;
        text-align: center;
        font-size: 30px;
        font-weight: 900;
        line-height: 40px;
        position: relative;
      }

      .sales-input::before,
      .rate::before,
      .sales-total::before,
      .sales-total2::before,
      .point::before,
      .real-total::before {
        content: "销售总额";
        display: block;
        line-height: 40px;
        font-size: 16px;
        font-weight: 900;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translateX(-50%);
      }

      .rate::before {
        content: "平臺費用百分比";
      }
      .sales-total::before,
      .sales-total2::before {
        content: "平臺費用";
      }
      .point::before {
        content: "平臺积分抵消";
      }
      .real-total::before {
        content: "实付平臺費用";
      }

      .input-prefix {
        font-style: normal;
        font-weight: 900;
        line-height: 40px;
      }
      .math-icon {
        font-style: normal;
        font-size: 30px;
      }
    }
  }
}
</style>